<template>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <h1 class="title">Ongoing Events</h1>
    <div class="w-100">
      <div v-for="(event, index) in eventGroups" :key="index" class="event-container">
        <div v-if="event.tasks.length" class="events">
          <div class="d-flex align-items-center">
            <router-link :to="{ name: 'event-details-preview', params: { slug: event.slug } }">
              <h2 class="name my-2 clickable-item-hov">
                {{ event.title }}

                ({{ event.tasks.length }})
              </h2>
            </router-link>
            <router-link
              :to="{ name: 'event-details-preview', params: { slug: event.slug } }"
              class="ml-auto clickable-item"
            >
              <ion-button class="end-btn"> See All </ion-button>
            </router-link>
          </div>
          <EventCard :task="task" v-for="task in (event.tasks || []).slice(0, 4)" />
        </div>
      </div>
      <div v-if="loading">
        <ion-skeleton-text v-for="i in 10" :animated="true" class="loader my-2" />
      </div>
      <div v-if="!loading && (!eventGroups || !eventGroups.length)">No ongoing quests. Check back later!</div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { toast } from '@/shared/native';
import { getAllActiveEvents } from '@/shared/actions/events';

import EventCard from './components/EventCardPreview.vue';

@Options({
  name: 'EventsPage',
  components: { EventCard },
})
export default class EventsPage extends Vue {
  public eventGroups: any = [];
  public loading = true;

  public async fetchActiveEventGroups() {
    try {
      this.loading = true;
      this.eventGroups = await getAllActiveEvents();
    } catch (e) {
      await toast.show('Could not load ongoing events. Please try again later.', 'nonative', 'danger');
    } finally {
      this.loading = false;
    }
  }

  public created() {
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    this.fetchActiveEventGroups();
  }
}
</script>
<style lang="sass" scoped>
h2
  font-size: 24px
h3
  font-size: 20px
  margin-top: 0.5rem
ul
  list-style-type: none
  padding: 0
  margin: 0.5rem 0 0 0
li
  margin-top: 0.4rem
.clickable-item:hover
  opacity: 0.7
.event-container
  border-radius: 10px
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none
.title
  font-size: 26px
  font-weight: bold
.desc-box
  border-radius: 10px
  background-color: #CCC
  color: black
  font-size: 12px
  .link-color
    color: #e3a7ff
.event-preview
  max-width: 100%
  img
    object-fit: contain
    border-radius: 10px
.countdown
  span
    color: gray !important
    font-size: 14px
.completed
  position: relative
  top: 2px
  font-size: 8px
  margin-left: 4px
.clickable-item-hov
  background: none
  opacity: 0.8
  &:hover
    opacity: 1
.name
  color: #547395
  font-weight: bold
.dark .name
  color: white !important
.task-name
  display: inline
.loader
  width: 300px
  height: 50px
  border-radius: 10px

.events
  border: 2px solid #547395
  border-radius: 12px
  margin-bottom: 15px
  padding: 10px

.end-btn
  height: 30px
  --border-radius: 20px
  text-transform: none
  font-size: 12px
</style>
